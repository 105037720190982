<template>
	<hotReqsType :baseUrlpath="baseUrlpath"></hotReqsType>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import hotReqsType from '@/components/hotReqsType.vue'
	export default {
		name: '热点',
		metaInfo: {
			title: '中东华人宝-热点',
			meta: [{
					name: 'keyWords',
					content: '中东华人宝-热点'
				},
				{
					name: 'description',
					content: '中东华人宝-热点'
				}
			]
		},
		components: {
			hotReqsType
		},
		data() {
			return {
				baseUrlpath: ''
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			var inchina = localStorage.getItem('inChina');
			if(inchina == '1'){
				this.baseUrlpath = this.baseUrlCN;
			}
		},
		methods: {}
	}
</script>

<style>
</style>