<template>
	<div>
		<div class="container">
			<div class="adwarp" @click="pushadvertise" v-if="zoneAds.length >= 3">
				<el-carousel :interval="4000" type="card" height="300px">
					<el-carousel-item v-for="(item,index) in zoneAds" :key="item.adPath + index" >
						<div class="advertise_bg">
							<div class="img">
								<img :src="baseUrlpath + item.adPath" />
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="adwarp" @click="pushadvertise" v-else-if="zoneAds.length > 0 && zoneAds.length < 3">
				<el-carousel :interval="4000" type="card" height="300px">
					<el-carousel-item v-for="(item,index) in zoneAds" :key="item.adPath + index" >
						<div class="advertise_bg">
							<div class="img">
								<img :src="baseUrlpath + item.adPath" />
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="adwarp" @click="pushadvertise" v-else>
				<el-carousel :interval="4000" type="card" height="300px">
					<el-carousel-item v-for="item in 6" :key="item" >
						<div class="advertise_bg">
							<div class="img"></div>
							<h3 class="medium">{{ '广告位'+item }}</h3>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="search-box">
				<div class="search-bd">
					<div class="search_c">
						<div class="search_item" v-for="(item,index) in hotCodes" @click="queryHotItem(item.shc_code_itemid)">
							#{{item.shc_code_itemname}}#
						</div>
					</div>
				</div>
				<div class="search_btn_warp" @click="dealSearch">
					<div class="search_btn">
						<div class="icon_search"></div>
						<div>搜索</div>
					</div>
					<div class="rest_btn" @click="goPublish">
						我要发布
					</div>
				</div>
			</div>
			<div v-loading="searchLoading" style="margin-top: 40px;">
				<div v-if="tableData.length > 0" class="search_body">
					<div class="req-warp" >
						<div v-for="(item,index) in tableData" :key="item.sdr_reqId" class="req-item itm_ml" >
							<commendReq :baseUrlpath="baseUrlpath" :item="item"></commendReq>
						</div>
					</div>
					<div class="pagination_warp">
						<el-pagination
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage1"
							:page-size="pageSize"
							layout="total, prev, pager, next"
							:total="total">
						</el-pagination>
					</div>
				</div>
				<div v-else class="empty-msg">
					<el-empty description="暂无内容"></el-empty>
				</div>
			</div>
			
		</div>

	</div>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	import commendReq from '@/components/commendReq.vue'
	export default {
		components: {
			commendReq
		},
		props: {
			baseUrlpath: {
				type: String,
				required: true
			},
			seachWord:{
				type: String,
				required: false
			}
		},
		data() {
			return {
				searchLoading:false,
				currentPage1: 1,
				labelCodeId:'',
				tableData: [],
				hotCodes:[],
				zoneAds:[],
				total: 0,
				pageSize: 8,
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				citysList:[0,0],
				searchAreaId:'',
				issm:false,
				isdb:false

			}
		},
		created() {
			this.citysList = JSON.parse(JSON.stringify(this.$util.citysList));
			this.citysList.unshift({id:'all',name:'不限',child:[{id:'all',name:'不限'}]})
			//this.queryZoneAd();
			this.getHotCodeTop();
			this.query();
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		methods: {
			dealSearch(){
				this.currentPage1 = 1;
				this.pageSize = 8;
				this.query();
			},
			changeClassify(){
				this.$router.push({
				  name: '/main/storeRecommendList',
				  query: {reqType:this.reqType}
				});
			},
			queryHotItem(labelCodeId){
				this.labelCodeId = labelCodeId
				this.dealSearch()
			},
			getHotCodeTop(){
				var vm = this;
				var params = {};
				if(localStorage.getItem("fayanboAreaId")){
					params.modelId = localStorage.getItem("fayanboAreaId")
				}
				var requestUrl = '/apis/getHotCodeTop'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/cnapis/getHotCodeTop'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.hotCodes = res.itemsList;
				});
			},
			query() {
				this.searchLoading = true
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				params.type = "all";
				if(localStorage.getItem("fayanboAreaId")){
					params.modelId = localStorage.getItem("fayanboAreaId")
				}
				if(this.seachWord != null && this.seachWord != undefined && this.seachWord.length > 0){
					params.seachWord = this.seachWord
				}
				if(this.labelCodeId){
					params.labelCodeId = this.labelCodeId
				}
				var requestUrl = '/apis/getRecommendReqs'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/cnapis/getRecommendReqs'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.tableData = res.reqList;
					vm.total = res.totalCount;
					vm.searchLoading = false
				});
			},
			async queryZoneAd() {
				var vm = this;
				var params = {};
				params.adLunchType = "1";
				params.reqType = this.reqType;
				var requestUrl = '/apis/queryAds'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/cnapis/queryAds'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.zoneAds = res.reList;
				});
			},
			handleCurrentChange(e){
				// console.log(e)
				this.currentPage1 = e;
				this.tableData = [];
				this.total = 0;
				this.query()
			},
			goPublish(){
				this.$router.push({
				  name: '/recommend/publish'
				});
			},
			pushadvertise(){
				if (!this.userInfo.fayanboId) {
					this.$util.warnMsg("请登录系统！")
					this.$store.dispatch('setLoginDialogvisible', true)
				} else {
					this.$router.push({
						name: '/my/advertisements'
					});
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.adwarp {
		margin-bottom: 10px;
	}
	.advertise_bg {
		height: 100%;
		position: relative;
		.img {
			height: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.medium {
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
	.store_guide {
		position: absolute;
		bottom: 10px;
		right: 20px;
		font-size: 24px;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #0000FF;
		text-decoration:underline;
		.more {
			background: url(../assets/icon/more.png) center no-repeat;
			background-size: 100%;
			width: 40px;
			height: 40px;
		}
	}
	
	.store_guide:hover {

	}
	.el-radio-group {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		.el-radio {
			margin-bottom: 16px;
		}
	}
	.search_body {
		min-height: 760px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		.pagination_warp {
			padding: 0 20px 20px 0;
			margin-top: 60px;
		}
	}
	.search-box {
		padding: 24px;
		box-shadow: 0 4px 16px rgba(69,88,115,.2);
		margin-bottom: 16px;
		background: #fff;
		border-radius: 12px;
		position: relative;
		.search-bd {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			min-height: 76px;
			.search_t {
				margin-bottom: 20px;
			}
			.search_c {
				margin-bottom: 20px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
				.search_item {
					font-size: 18px;
					margin-bottom: 20px;
					color: blue;
					text-decoration: underline;
					cursor: pointer;
					.el-checkbox__label {
						font-size: 18px;
					}
					.sear_it_head {
						font-size: 18px;
					}
				}
				.search_item:not(:last-child) {
					margin-right: 20px;
				}
			}

		}
		.search_btn_warp {
			display: flex;
			position: absolute;
			left: 50%;
			bottom: -46px;
			position: absolute;
			transform: translate(-50%,-50%);
			.search_btn {
				background-image: linear-gradient(-90deg,#c88854,#e09a62);
				border-radius: 28px;
				box-shadow: 0 4px 16px 0 rgba(255,102,0,.32);
				width: 132px;
				height: 46px;
				border: none;
				display: block;
				outline: none;
				font-size: 18px;
				color: #fff;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				margin-right: 8px;
				.icon_search {
					background: url(../assets/icon/search_empty.png) no-repeat center;
					background-size: 100%;
					width: 26px;
					height: 26px;
					margin-right: 6px;
				}
			}
			.rest_btn {
				width: 132px;
				height: 46px;
				color: #C88854;
				border: 1px solid #C88854;
				line-height: 46px;
				cursor: pointer;
				border-radius: 28px;
				font-size: 18px;
				box-sizing: border-box;
				margin-left: 8px;
				background-color: #FFFFFF;
			}
			.rest_btn:hover {
				border: none;
				background-color: #C88854;
				color: #FFF7F0;
				outline: none;
			}
		}
	}
	.req-warp {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		.req-item {
			width: calc((100% - 36px) / 4);
			border-radius: 8px;
			box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
			
			margin-bottom: 14px;
		}
		.itm_ml:not(:nth-child(4n)) {
			margin-right: 12px;
		}
	}

	.container {
		width: 90%;
		margin: 0 auto;
		background-color: #FFFFFF;
		.empty-msg {
			flex: 1;
			height: calc(100vh - 70px);
			display: flex;
			justify-content: center;
		}
	}

	
</style>
